import GhPlugin from "./GhPlugin";


export default class ShopPlugin extends GhPlugin {

  shouldRun() {
    return $('body').hasClass('page-shop');
  }


  init() {
    this.colorboxit();
  }

  colorboxit() {
    $('.view-material-abverkauf .views-row .group-image-wrapper img').each((index, el) => {
      let src = $(el).attr('src');

      src = src.replace('/styles/product/public', '');

      $(el).wrap(`<a href="${src}" class="colorbox"></a>`);
    });

    Drupal.attachBehaviors();
  }
}
