import GhPlugin from "./GhPlugin";

export default class MobileNavPlugin extends GhPlugin {
  shouldRun() {
    return true;
  }

  init() {
    // $('.mobile-nav li.menu-item--expanded > a').each(function() {
    //   $(this).after('<div class="show-submenu"><i class="fa fa-plus"></i><i class="fa fa-minus"></i></div>');
    // });
    //
    // $('.mobile-nav li.menu-item--expanded.menu-item--active-trail').addClass('open');
  }

  addEventListeners() {
    // $('.mobile-nav li.menu-item--expanded > .show-submenu').click(function() {
    //   let $li = $(this).closest('li.menu-item--expanded');
    //
    //   $li.toggleClass('open');
    // });

    $('.mobile-nav-toggle, .mobile-nav ul.menu li a').click(function() {
      $('.mobile-nav').toggleClass('is-active');
      $('.mobile-nav-overlay').toggleClass('is-active');
    });
  }
}
