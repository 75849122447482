/**
 * Base class for Grasenhiller Plugins
 */
export default class GhPlugin {
  constructor() { }

  /**
   * The plugin is only executed when this function returns true.
   * Could be used for checking if this plugin is neccessary on this page.
   * @returns {boolean} [true]
     */
  shouldRun() {
    return true;
  }

  /**
   * Run the plugin.
   */
  run() {
    if (this.shouldRun()) {
      this.init();
      this.addEventListeners();
    }
  }

  /**
   * Add event listeners.
   * Called after init.
   */
  addEventListeners() { }

  /**
   * Initializes the plugin.
   * Called in run.
   */
  init() { }
}
