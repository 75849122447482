import GhPlugin from "./GhPlugin";
export default class ScrollNavPlugin extends GhPlugin {

  shouldRun() {
    return true;
  }

  init() {
    $("header #block-system-main-menu a").click(function(e) {
      let href = $(this).attr('href');

      let targetId = href.match(new RegExp('#(.*)'))[0];
      let page = href.match(new RegExp('(.*)#'))[1];

      if (targetId != '' && page == window.location.pathname) {
        e.preventDefault();



        $('html, body').animate({
          scrollTop: $(targetId).offset().top - 150,
        });
      }
    });
  }
}
