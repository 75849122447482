Drupal = Drupal || {behaviors: {}};
jQuery = jQuery || {};

var Merkzettel = function() {
    return {
        items: [],
        infoShown: false,
        initialized: false,
        supported: false,
        minimized: false,

        addItem: function(title, nummer, preis) {
            var existing = false;

            jQuery.each(this.items, function(index, item){
                if (item.nummer == nummer) {
                    existing = true;
                }
            });

            if (!existing){
                this.items.push({
                    title: title,
                    nummer: nummer,
                    preis: preis
                });
                this.renderMerkzettel();

                if (!this.infoShown) {
                    this.showInfo();
                }

                this.saveData();
            }
        },

        removeItem: function(index) {
            this.items.splice(index, 1);
            this.renderMerkzettel();
            this.saveData();
        },

        showInfo: function() {
            var self = this;

            this.infoShown = true;

            var html;
            html = '<div class="merkzettelInfo">' +
                     '<div class="content">' +
                       '<div class="text">' +
                         '<h1>Produkt wurde zum Merkzettel hinzugefügt</h1>' +
                         '<p>Sie haben jetzt die Möglichkeit weitere Produkte zu Ihrem Merkzettel hinzuzufügen und dann eine gesammelte Anfrage zu stellen.</p>' +
                         '<div class="button-wrapper">' +
                           '<a class="button">Ok, verstanden</a>' +
                         '</div>' +
                       '</div>' +
                       '<i class="fa fa-arrow-right"></i>' +
                     '</div>' +
                   '</div>';
            jQuery('body').append(html);

            jQuery('.merkzettelInfo a.button').click(function(e){
                e.preventDefault();
                self.hideInfo();
            });

            if (jQuery(document).width() < 400) {
                this.minimize();
            }
        },

        hideInfo: function() {
            jQuery('.merkzettelInfo').remove();
        },

        renderMerkzettel: function() {
            var self = this;
            var itemString;
            var minimizedClass;
            var html;
            minimizedClass = ''
            itemString = '- ';

            // Remove existing
            jQuery('.merkzettel').remove();

            // Do not show when no items present
            if (this.items.length == 0) {
                return;
            }

            if (this.minimized) {
                minimizedClass = ' minimized';
            }

            html = '<div class="merkzettel'+ minimizedClass +'">' +
                '<h3><i class="fa fa-chevron-down"></i>Merkzettel<span class="count"> ('+ this.items.length +')</span></h3>' +
                '<ul>';

            jQuery.each(this.items, function(index, item){
                itemString += item.title + "\n- ";
                html += '<li data-index="'+ index +'"><i class="fa fa-close"></i>' + item.title + '</li>';
            });

            itemString = itemString.substr(0, itemString.length - 2);
            itemString = encodeURIComponent(itemString);

            html += '</ul>' +
                '<a class="button" href="#" data-featherlight="/ajax-node/45?produkt='+ itemString +'" data-featherlight-type="ajax">Anfrage absenden</a>' +
                '</div>';

            jQuery('body').append(html);

            jQuery('.merkzettel li i').click(function() {
                var $parent = jQuery(this).parents('li');
                var index = $parent.data('index');
                self.removeItem(index);
            });

            // Minimize when button clicked
            jQuery('.merkzettel h3').click(function(){
                if (jQuery(this).parents('.merkzettel').hasClass('minimized')){
                    self.maximize();
                } else {
                    self.minimize();
                }
            });
        },

        minimize: function() {
            jQuery('.merkzettel').addClass('minimized');
            this.minimized = true;
            localStorage.setItem('merkzettelMinimized', 'true');
        },

        maximize: function() {
            jQuery('.merkzettel').removeClass('minimized');
            this.minimized = false;
            localStorage.setItem('merkzettelMinimized', 'false');
        },

        loadSavedData: function() {
            this.items = JSON.parse(localStorage.getItem('merkzettelItems'));
            this.minimized = localStorage.getItem('merkzettelMinimized') == 'true';

            if (!this.items) {
                this.items = [];
            }
        },

        saveData: function() {
            localStorage.setItem('merkzettelItems', JSON.stringify(this.items));
        },

        init: function() {
            // Check if localstorage is supported
            if(typeof(Storage) !== "undefined") {
                this.supported = true;
                this.loadSavedData();
                if (this.items.length > 0) {
                    this.renderMerkzettel();
                }
            } else {
                this.supported = false;
            }

            this.initialized = true;
        }
    }
};

var merkzettel = new Merkzettel();

Drupal.behaviors.merkzettel = {
  attach: function(context, settings) {
      if (!jQuery('body').hasClass('page-shop')) {
          return;
      }

      var $ = jQuery;
      if (!merkzettel.initialized) {
          merkzettel.init();
      }


      $('.view-material-abverkauf .merkzettel-button', context).once('merkzettelClick', function() {
         $(this).click(function(e) {
             if (merkzettel.supported) {
                 e.preventDefault();
                 var $row  = $(this).parents('.views-row');

                 var title = $row.find('.field-name-title').text();
                 var nummer = $(this).data('nid');
                 var preis = $row.find('.field-name-field-preis').text();

                 merkzettel.addItem(title, nummer, preis);
             } else {
               e.preventDefault();
               var $row  = $(this).parents('.views-row');
               var title = $row.find('.field-name-title').text();
               $('body').append('<a class="button" id="legacyorderbutton" href="#" data-featherlight="/ajax-node/45?produkt='+ encodeURIComponent(title) +'" data-featherlight-type="ajax">Anfrage absenden</a>');
               $('#legacyorderbutton').click();
             }
         })
      });
  }
};

jQuery(document).ajaxComplete(function(){
    Drupal.attachBehaviors();
});
