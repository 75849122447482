import './gh-plugins/tables';
import './gh-plugins/merkzettel';

import MobileNavPlugin from './inc/MobileNavPlugin';
import ScrollNavPlugin from "./inc/ScrollNavPlugin";
import ShopPlugin from "./inc/ShopPlugin";

jQuery(document).ready(function($) {
  window.$ = $;
  new MobileNavPlugin().run();
  new ScrollNavPlugin().run();
  new ShopPlugin().run();

  if($('html').attr('lang') == 'en') {
    $('[for="edit-submitted-telefon"]').text('Phone');
    $('[for="edit-submitted-ihre-nachricht"]').html('Message <span class="form-required" title="This field is required.">*</span>');
    $('.field-name-custom-code-maps span').text('open on maps');
  } else {
    $('[for="edit-submitted-telefon"]').text('Telefon');
    $('[for="edit-submitted-ihre-nachricht"]').html('Ihre Nachricht <span class="form-required" title="This field is required.">*</span>');
    $('.field-name-custom-code-maps span').text('Maps öffnen');
  }

  // - Replace links with Grasenhiller Logo
  var ghLogo = '<img height="25" width="140" style="max-width: 140px !important;" src="/sites/all/themes/gh_custom/dist/images/grasenhiller.png" alt="Grasenhiller - Webdesign in Neumarkt, Amberg, Nürnberg & Weiden" />'
  $('a.grasenhiller-logo').html(ghLogo);

  $('.node-service.view-mode-default').append('<div class="arrow"></div>');

  $('.node-service.view-mode-default').click(function(event) {
    var nid = $(this).find('.nid').data('nid');

    $('.field-name-field-services-bild').hide();
    $('.node-service').removeClass('active');
    $('.node-service').find('.nid[data-nid="'+ nid +'"]').parents('.node-service').addClass('active');
  });


  // Services umstrukturieren
  var $servicesOriginal = $('section > .field-name-field-services');
  var $servicesClone = $servicesOriginal.clone(true).insertAfter('section > .field-name-services-body');
  $servicesClone.addClass('clone');

  $servicesOriginal.find('.node-service:nth-child(1n+5)').remove();
  $servicesClone.find('.node-service:nth-child(-1n+4)').remove();

  // - Match height
  // $('.row').matchHeight({
  //   byRow: true,
  //   property: 'height',
  //   target: null,
  //   remove: false
  // });
});

jQuery.fn.slideFadeToggle = function(speed, easing, callback) {
  return this.animate({opacity: 'toggle', height: 'toggle'}, speed, easing, callback);
};

jQuery(document).ajaxComplete(function(event, xhr, settings) {

});
